import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import { INameSlug, INodePostSummary } from '../interfaces/blog';

interface IProps {
  posts: INodePostSummary[];
}

export const PostsListGrid: React.FC<IProps> = ({ posts }) => (

  <div className="row row--flex-wrap">
    {posts.map(({ node: post }) => (
      <div
        key={post.id}
        className="post-slide row__large-4 row__medium-6 row__small-12"
      >
        <div className="post-slide__image-wrapper">
          {
            post && post.featured_media ? (
              <Img
                className="post-slide__image"
                alt={post.featured_media.alt_text}
                fluid={{ ...post.featured_media.localFile.childImageSharp.fluid, aspectRatio: 1 }}
              />
            ) : null
          }
        </div>
        <div className="post-slide__tags">
          {
            post.tags && post.tags.length ? (
              (post.tags as Array<INameSlug>).map(tag => (
                <Link
                  key={tag.slug}
                  to={`/tags/${tag.slug}/`}
                  className="post-slide__tag-link"
                >
                  <span className="tag tag--blue tag--uppercase">{tag.name}</span>
                </Link>
              ))
            ) : null
          }
        </div>
        <Link className="post-slide__title" to={`/${post.slug}/`}>
          {post.title}
        </Link>
      </div>
    ))}
  </div>
);

export const pageQuery = graphql`
    fragment PostListGrid on wordpress__POST {
        id
        title
        featured_media {

           alt_text
           localFile {
               childImageSharp {
                   fluid(maxWidth: 352) {
                       ...GatsbyImageSharpFluid
                   }
               }
           }
        }
        tags {
            id
            name
            slug
        }
        author {
            name
            slug
            avatar_urls {
                wordpress_48
            }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
    }
`;
