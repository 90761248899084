/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import { IBlogPageContext } from '../interfaces/pageContext';

interface IPagination {
  pageContext: IBlogPageContext['pageContext'];
  path: string;
}

export const Pagination: React.FC<IPagination> = ({ pageContext, path }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext;

  const PREV = { rel: 'prev' };
  const NEXT = { rel: 'next' };
  const pageMiddle = humanPageNumber !== 1 && humanPageNumber !== numberOfPages ? {} : humanPageNumber === 1 ? { rel: 'next' } : { rel: 'prev' };

  const currentPagePath = (prevPage: string) => {
    const prevPageSplited = prevPage.split('/');
    const prevNumber = Number(prevPageSplited[2]);
    if (prevNumber) {
      return `/${prevPageSplited[1]}/${prevNumber + 1}/`;
    }
    return `/${prevPageSplited[1]}/2/`;
  };

  return (
    <nav className="pagination" role="navigation" aria-label="Pagination Navigation">
      <ul className="pagination__list">
        {
          numberOfPages === 1 ? null : (
            numberOfPages === 2 ? (
              <>
                <li className="pagination__list-item">
                  <Link
                    className="pagination__list-link"
                    to={humanPageNumber === 1 ? path : previousPagePath}
                    aria-current={humanPageNumber === 1}
                    aria-label="Idź do strony 1"
                    {...humanPageNumber === 1 ? {} : { ...PREV }}
                  >
                    1
                  </Link>
                </li>
                <li className="pagination__list-item">
                  <Link
                    className="pagination__list-link"
                    to={humanPageNumber === 2 ? `${path}` : `${nextPagePath}/`}
                    aria-current={humanPageNumber === 2}
                    {...humanPageNumber === 2 ? {} : { ...NEXT }}
                    aria-label="Idź do strony 2"
                  >
                    2
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="pagination__list-item">
                  <Link
                    className="pagination__list-link"
                    to={humanPageNumber === 1 ? `${path.substring(0, path.length - 1)}` : `${previousPagePath}/`}
                    aria-current={humanPageNumber === 1}
                    aria-label={`Idź do strony ${humanPageNumber === 1 ? '1' : humanPageNumber === numberOfPages ? humanPageNumber - 2 : humanPageNumber - 1} `}
                    {...humanPageNumber === 1 ? {} : { ...PREV }}
                  >
                    {humanPageNumber === 1 ? '1' : '<'}
                  </Link>
                </li>
                <li className="pagination__list-item">
                  <Link
                    className="pagination__list-link"
                    to={humanPageNumber === numberOfPages ?
                      `${previousPagePath}/` :
                      humanPageNumber === 1 ?
                        `${nextPagePath}/` :
                        currentPagePath(previousPagePath)}
                    aria-current={humanPageNumber !== 1 && humanPageNumber !== numberOfPages}
                    aria-label={`Idź do strony ${humanPageNumber === 1 ? '2' : humanPageNumber === numberOfPages ? numberOfPages - 1 : humanPageNumber}`}
                    {...pageMiddle}
                  >
                    {humanPageNumber === 1 ? '2' : humanPageNumber === numberOfPages ? numberOfPages - 1 : humanPageNumber}
                  </Link>
                </li>
                <li className="pagination__list-item">
                  <Link
                    className="pagination__list-link"
                    to={humanPageNumber === numberOfPages ? `${path.substring(0, path.length - 1)}` : `${nextPagePath}/`}
                    aria-current={humanPageNumber === numberOfPages}
                    {...humanPageNumber === numberOfPages ? {} : { ...NEXT }}
                    aria-label={`Idź do strony ${humanPageNumber === 1 ? 3 : humanPageNumber === numberOfPages ? numberOfPages : humanPageNumber + 1}`}

                  >
                    {humanPageNumber === numberOfPages ? numberOfPages : '>'}
                  </Link>
                </li>
              </>
            )
          )
        }
      </ul>
    </nav>
  );
};
