/* eslint-disable import/no-default-export */
import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';

import { PostsListGrid } from '../components/PostsListGrid';
import { Pagination } from '../components/Pagination';
import { IBlogPageContext } from '../interfaces/pageContext';
import { IBlogPostSummary, IAllWordpressTags } from '../interfaces/blog';
// import { HeroBlogImage } from '../img/svg/HeroBlogImage';
// import { TagPosts } from '../components/TagPosts';

type IBlogPosts = IBlogPostSummary & IBlogPageContext & IAllWordpressTags & { path: string};

export default function PostsPage(props: IBlogPosts) {
  const { data, pageContext, path } = props;
  const { edges: posts } = data.posts;

  return (
    <Layout>
      <Helmet title="Wszystkie posty | swiatubezpieczen.com" />
      <section id="hero" className="section section--blog-hero-image">
        <div className="wrapper" />
      </section>

      <section id="newest" className="section section--carousel-newest">
        <div className="wrapper">
          <span className="heading-short">Blog</span>
          <h2 className="heading">Wszystkie posty</h2>
          <PostsListGrid posts={posts} />
        </div>
      </section>
      <section className="section section--pagination">
        <div className="wrapper">
          <Pagination pageContext={pageContext} path={path} />
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
    query PostsQuery($limit: Int!, $skip: Int!) {
        posts: allWordpressPost(
            sort: { fields: date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...PostListGrid
                }
            }
        }
    }
`;
